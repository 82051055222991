import type { TokenDataSummary } from '@knapsack/design-token-utils';
import type {
  Role,
  KsAppClientDataNoMeta,
  KsChange,
  KsRemoteChange,
  FeedbackTypes,
} from '@knapsack/types';
import type { DeepPartial } from 'utility-types';
import type { Typestate, Interpreter } from 'xstate';
import { ReactNode } from 'react';
import type { MachineStateSchemaPaths } from '../../xstate.utils';
import type { AppContext, SharedEvents } from '../app/app.xstate-utils';
import {
  emptyAppClientData,
  type AppClientDataReducerEvents,
} from './reducers';
import { DataChangesBroadcastChannel } from './utils/data-changes-broadcast-channel';

export interface AppClientDataCtx {
  initial?: KsAppClientDataNoMeta;
  active: KsAppClientDataNoMeta;
  tokenData: TokenDataSummary;
  tokenStyles: string;
  initialVsActiveDiff: DeepPartial<KsAppClientDataNoMeta>;
  errors: string[];
  past: KsChange[];
  saveStack: KsChange[];
  dataChangesBroadcastChannel?: DataChangesBroadcastChannel;
  saverError?: string;
  /** errors from loading App Client Data */
  loaderError?: string;
  user?: {
    userId: string;
    roleForSite: Role;
  };
  site?: AppContext['site'];
  branchesStatusMsg?: {
    msg: ReactNode;
    type: FeedbackTypes;
  };
}

export const initialCtx: AppClientDataCtx = {
  active: emptyAppClientData,
  initialVsActiveDiff: {},
  errors: [],
  past: [],
  saveStack: [],
  tokenStyles: '',
  tokenData: {
    tokens: [],
    tokensById: {},
    groups: [],
    groupsById: {},
    globalConfig: {},
    collections: [],
    collectionsById: {},
  },
};

/* eslint-disable @typescript-eslint/ban-types */
export interface AppClientDataState {
  states: {
    empty: {};
    ready: {
      states: {
        data: {};
        status: {
          states: {
            current: {};
            changed: {};
          };
        };
        branches: {
          states: {
            unknown: {};
            latest: {
              states: {
                idle: {};
                creatingBranch: {};
                error: {};
              };
            };
            branch: {
              states: {
                clean: {
                  states: {
                    needsFirstCommit: {};
                    idle: {};
                    publishing: {};
                    creatingPr: {};
                    error: {};
                  };
                };
                hasChanges: {};
                committing: {};
                discarding: {};
                error: {};
              };
            };
          };
        };
        saver: {
          states: {
            idle: {};
            pushing: {};
            // pulling: {};
            error: {};
          };
        };
      };
    };
  };
}
/* eslint-enable @typescript-eslint/ban-types */

export type AppClientDataEvents =
  | AppClientDataReducerEvents
  | SharedEvents
  | {
      type: 'appClientData.externallyChanged';
      subtype: 'fullData';
      appClientData: KsAppClientDataNoMeta;
    }
  | {
      type: 'appClientData.externallyChanged';
      subtype: 'changes';
      changes: KsRemoteChange[];
    }
  | { type: 'appClientData.saverError'; errorMsg: string }
  | { type: 'appClientData.saverRetry' }
  | { type: 'appClientData.saver.save' }
  | { type: 'appClientData.prepForInstanceSwitch' }
  | { type: 'appClientData.createBranch'; branchName: string }
  | { type: 'appClientData.createBranch.done' }
  | { type: 'appClientData.createBranch.error'; errorMsg: string }
  | { type: 'appClientData.createPr'; title: string; body?: string }
  | { type: 'appClientData.createPr.done' }
  | { type: 'appClientData.createPr.error'; errorMsg: string }
  | { type: 'appClientData.publishBranch'; title?: string; body?: string }
  | { type: 'appClientData.publishBranch.done' }
  | { type: 'appClientData.publishBranch.error'; errorMsg: string }
  | { type: 'appClientData.commitChanges'; commitMessage: string }
  | { type: 'appClientData.commitChanges.done' }
  | { type: 'appClientData.commitChanges.error'; errorMsg: string }
  | { type: 'appClientData.clearStatusMsg' }
  | {
      type: 'appClientData.setStatusMsg';
      status: AppClientDataCtx['branchesStatusMsg'];
    }
  | { type: 'appClientData.commitErrorReset' }
  | { type: 'appClientData.branchErrorReset' }
  | { type: 'appClientData.discardAllChanges.done' }
  | { type: 'appClientData.discardAllChanges.error'; errorMsg: string }
  | { type: 'appClientData.discardAllChanges' }
  | {
      type: 'dataDoctor.run';
    };

export interface AppClientDataTypestates extends Typestate<AppClientDataCtx> {
  context: AppClientDataCtx;
  value: MachineStateSchemaPaths<AppClientDataState['states']>;
}

export type AppClientDataInterpreter = Interpreter<
  AppClientDataCtx,
  AppClientDataState,
  AppClientDataEvents,
  AppClientDataTypestates
>;
