import {
  KsAppClientDataNoMeta,
  type KsRemoteChange,
  isKsRemoteChanges,
} from '@knapsack/types';
import { gqlAppLoadClient } from '@/services/app-loading';
import { getAppClientData } from '@/services/app-client.client';
import { appClientApiPath } from '@/utils/constants';
import { getActiveEnvUrl } from '@/core/env-and-content-src/env-url-storage';
import {
  getEnvDetails,
  getEnvDetailsFromAppClientData,
  getEnvWebsocketUrl,
  isUrlDevelopment,
} from '@/core/env-and-content-src/utils';

import { featureFlags } from '@/utils/feature-flags';
import type { SiteStatuses_Enum } from '@knapsack/hasura-gql-client';
import {
  ContentSrc,
  Env,
  EnvProd,
} from '@/core/env-and-content-src/env-and-content-src.types';
import { appApiGql, KsAppApiGqlClientError } from '@/services/app-api-client';
import type {
  SiteLoadResults,
  SiteMeta,
} from '../../xstate/machines/app/sub-states/site.xstate-types';
import { AppClientMeta, extractMetaState } from './extract-meta-state';

type SiteLoad = Promise<SiteLoadResults>;

export type SiteLoadErrorInfo =
  | { type: 'siteId-not-found'; siteId: string }
  | {
      type: 'outdated-app-client';
      currentVersion: string;
      requiredVersion: string;
    }
  | {
      type: 'app-client-connection-failed';
      appClientUrl: string;
    }
  | {
      type: 'app-client-deployments-not-set-up';
    }
  | {
      type: 'private-site.user-not-logged-in';
    }
  | {
      type: 'private-site.user-not-allowed-to-view-site';
    }
  | { type: 'unknown'; message: string };

const { loadContentViaApi } = featureFlags;

export class SiteLoadError extends Error {
  info: SiteLoadErrorInfo;
  constructor(info: SiteLoadErrorInfo) {
    switch (info.type) {
      case 'siteId-not-found':
        super(`Site ID "${info.siteId}" not found`);
        break;
      case 'outdated-app-client':
        super(`App Client is outdated`);
        break;
      case 'app-client-connection-failed':
        super(
          `App Client connection failed, please ensure this url is accessible: ${info.appClientUrl}`,
        );
        break;
      case 'unknown':
        super(info.message);
        break;
      case 'app-client-deployments-not-set-up':
        super(
          'App Client deployments are not set up yet, this workspace only can be ran locally.',
        );
        break;
      case 'private-site.user-not-logged-in':
        super('This is a private site. You must be logged in.');
        break;
      case 'private-site.user-not-allowed-to-view-site':
        super(
          'This workspace is private and you do not have permission to view it.',
        );
        break;
      default: {
        // `never` uses TS to ensure we handle all cases in the switch
        const _exhaustiveCheck: never = info;
        super(`SiteLoadError`);
        break;
      }
    }
    this.name = 'SiteLoadError';
    this.info = info;
  }
}

async function getAppClientDataAndCheckMeta({
  appClientUrl,
  siteStatus,
  siteId,
}: {
  appClientUrl: string;
  siteStatus: SiteStatuses_Enum;
  siteId: string;
}): Promise<{
  appClientData: KsAppClientDataNoMeta;
  appClientMeta: AppClientMeta;
}> {
  switch (siteStatus) {
    case 'PREPARING': {
      throw new SiteLoadError({
        type: 'unknown',
        message: `Workspace is currently in a "Preparing" state. Please try again later.`,
      });
    }
    case 'INACTIVE':
    case 'ARCHIVED':
    case 'DISABLED': {
      throw new SiteLoadError({
        type: 'unknown',
        message: `Workspace is currently in a "${siteStatus}" state. Please contact support: help@knapsack.cloud`,
      });
    }
    case 'ACTIVE':
      break; // we're good, continue
    default: {
      const _exhaustedCheck: never = siteStatus;
      throw new Error(`Unhandled siteStatus: ${siteStatus}`);
    }
  }

  const { metaState, ...appClientData } = await getAppClientData({
    appClientUrl,
  }).catch((error) => {
    console.error(`Error loading App Client Data`, error);
    throw new SiteLoadError({
      type: 'app-client-connection-failed',
      appClientUrl,
    });
  });
  const appClientMeta = extractMetaState(metaState);
  if (appClientMeta.knapsackCloudSiteId !== siteId) {
    throw new SiteLoadError({
      type: 'unknown',
      message: `App Client Data is for a different site. Expected "${siteId}" but got "${appClientMeta.knapsackCloudSiteId}"`,
    });
  }

  return {
    appClientData,
    appClientMeta,
  };
}

// Site running local
async function loadSiteFromClientUrl({
  appClientUrl,
}: {
  appClientUrl: string;
}): SiteLoad {
  try {
    const _appClientApiEndpoint = new URL(
      appClientApiPath,
      appClientUrl,
    ).toString();
  } catch (error) {
    throw new SiteLoadError({
      type: 'unknown',
      message: `This is not a proper url: "${appClientUrl}"`,
    });
  }

  const { metaState, ...appClientData } = await getAppClientData({
    appClientUrl,
  }).catch((error) => {
    console.error(`Error loading App Client Data`, error);
    throw new SiteLoadError({
      type: 'app-client-connection-failed',
      appClientUrl,
    });
  });

  const appClientMeta = extractMetaState(metaState);

  const info = await gqlAppLoadClient.GetSiteBasicInfo({
    siteId: appClientMeta.knapsackCloudSiteId,
  });
  if (!info?.site?.id) {
    throw new SiteLoadError({
      type: 'siteId-not-found',
      siteId: appClientMeta.knapsackCloudSiteId,
    });
  }
  const {
    gitProvider,
    planId,
    status,
    isPrivate,
    latestUrl,
    orgId,
    repoUrl,
    id: siteId,
  } = info.site;

  const env: Env = isUrlDevelopment(appClientUrl)
    ? {
        type: 'development',
        appClientMeta,
        url: appClientUrl,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
        websocketsEndpoint: getEnvWebsocketUrl({
          envUrl: appClientUrl,
          websocketsPort: appClientMeta.websocketsPort,
        }),
      }
    : {
        type: 'preview',
        appClientMeta,
        url: appClientUrl,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
      };
  const contentSrc: ContentSrc = {
    type: 'current-env-server',
  };
  const meta: SiteMeta = {
    siteId,
    isPrivate,
    repoUrl,
    gitProviderType: gitProvider,
    status,
    plan: planId,
    orgId,
    prodEnvUrl: latestUrl,
  };
  return {
    appClientData,
    latestDataChanges: [],
    instanceDataChanges: [],
    site: {
      env,
      meta,
      contentSrc,
    },
  };
}

const getSiteContentAndMeta = async () => {
  try {
    return await appApiGql.getSiteContentAndMeta();
  } catch (e) {
    if (e instanceof KsAppApiGqlClientError) {
      if (e.errorCode === 'ks.private-site.user-not-logged-in') {
        throw new SiteLoadError({
          type: 'private-site.user-not-logged-in',
        });
      }
      if (e.errorCode === 'ks.private-site.user-not-allowed-to-view-site') {
        throw new SiteLoadError({
          type: 'private-site.user-not-allowed-to-view-site',
        });
      }
    }
    throw new SiteLoadError({
      type: 'unknown',
      message: e.message,
    });
  }
};

export async function loadSiteById({
  siteId,
  instanceId,
}: {
  siteId: string;
  instanceId: string;
}): Promise<SiteLoadResults> {
  if (siteId === 'custom') {
    throw new Error(`Cannot use this function to load siteId "custom".`);
  }

  // ================ NEW STUFF!  ===============
  // Feature flagged application of patches at API instead of UI
  if (loadContentViaApi) {
    const {
      site: {
        appClientDataInfo: {
          appClientData,
          lastDataChangeId,
          instanceStatus,
          gitBranch,
          lastCommittedDataChangeId,
        },
        meta,
      },
    } = await getSiteContentAndMeta();

    const {
      orgId,
      prodEnvUrl,
      repoUrl,
      siteId: loadedSiteId,
      isPrivate,
      gitProviderType,
      plan,
      status,
    } = meta;

    if (!loadedSiteId)
      throw new SiteLoadError({
        type: 'siteId-not-found',
        siteId: loadedSiteId,
      });
    if (!prodEnvUrl)
      throw new SiteLoadError({
        type: 'app-client-deployments-not-set-up',
      });

    // @TODO: Do we need lastDataChangeId to figure out if we must apply patches from
    // PUBLISHING instances?

    // null here means no environment url, therefore there is only PROD url
    const activeEnvUrl = getActiveEnvUrl({ siteId });

    // Saves a network request here by building env if we already have appClientData
    const env = activeEnvUrl
      ? await getEnvDetails({ envUrl: activeEnvUrl, prodEnvUrl })
      : getEnvDetailsFromAppClientData({
          appClientData,
          envUrl: prodEnvUrl, // no activeEnvUrl means prod must be envUrl
          prodEnvUrl,
        });
    if (env instanceof Error) {
      throw new Error(
        `Extracting meta from appClientData failed. Errors: ${env.message}`,
      );
    }

    const siteMeta: SiteMeta = {
      siteId,
      isPrivate,
      repoUrl,
      gitProviderType,
      status,
      plan,
      orgId,
      prodEnvUrl,
    };

    // Default to latest for SiteLoadResults, override contentSrc cloud authoring branches
    const defaultContentSrc: ContentSrc = {
      type: 'cloud-authoring',
      instance: {
        type: 'latest',
      },
    };
    const siteLoadResults: SiteLoadResults = {
      appClientData,
      site: {
        contentSrc: defaultContentSrc,
        env,
        meta: siteMeta,
      },
      instanceDataChanges: [], // No need for these anymore
      latestDataChanges: [], // No need for these anymore
    };

    switch (instanceId) {
      case 'draft': {
        throw new Error(`InstanceTag "draft" is not yet implemented.`);
      }
      case 'latest': {
        return siteLoadResults;
      }
      default: {
        // We won't have this if instance failed to load
        if (!instanceStatus) {
          return loadSiteById({ siteId, instanceId: 'latest' });
        }

        const cloudAuthoringContentSrc: ContentSrc = {
          type: 'cloud-authoring',
          instance: {
            type: 'branch',
            instanceId,
            instanceStatus,
            lastCommittedDataChangeId,
            gitBranch,
          },
        };
        siteLoadResults.site.contentSrc = cloudAuthoringContentSrc;

        return siteLoadResults;
      }
    }
  }

  // ============== END NEW STUFF ==================

  switch (instanceId) {
    // @todo implement `draft` instanceTag (i.e. `/site/${siteId}/draft`)
    case 'draft': {
      throw new Error(`InstanceTag "draft" is not yet implemented.`);
    }
    case 'latest': {
      const { site } = await gqlAppLoadClient.GetSiteAndLatestInstance({
        siteId,
      });
      if (!site?.id) {
        throw new SiteLoadError({
          type: 'siteId-not-found',
          siteId,
        });
      }
      const {
        gitProvider,
        isPrivate,
        repoUrl,
        status: siteStatus,
        orgId,
        latestUrl,
        siteInstancesPublishing = [],
        planId,
      } = site;
      if (!latestUrl) {
        throw new SiteLoadError({
          type: 'app-client-deployments-not-set-up',
        });
      }
      const { appClientData, appClientMeta } =
        await getAppClientDataAndCheckMeta({
          siteId,
          appClientUrl: latestUrl,
          siteStatus,
        });

      // All the Immer Patches from every Site Instance (branch) with a status of
      // "Publishing" that has been published but likely not deployed yet
      // These are in order from oldest to newest
      // We apply these patches on Latest to have the data show up before the
      // workspace repo has deployed the App Client that contains those data updates
      // Elsewhere we change Site Instances status from Publishing -> Published
      // after they have been deployed
      const latestDataChanges: KsRemoteChange[] =
        siteInstancesPublishing.flatMap(({ dataChanges }) => {
          return isKsRemoteChanges(dataChanges) ? dataChanges : [];
        });

      let env: Env = {
        type: 'production',
        url: latestUrl,
        appClientMeta,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
      };
      const activeEnvUrl = getActiveEnvUrl({ siteId });
      if (activeEnvUrl) {
        const envDetails = await getEnvDetails({
          envUrl: activeEnvUrl,
          prodEnvUrl: latestUrl,
        });
        if (!(envDetails instanceof Error)) {
          env = envDetails;
        }
      }
      const contentSrc: ContentSrc = {
        type: 'cloud-authoring',
        instance: {
          type: 'latest',
        },
      };
      const meta: SiteMeta = {
        siteId,
        isPrivate,
        repoUrl,
        gitProviderType: gitProvider,
        status: siteStatus,
        plan: planId,
        orgId,
        prodEnvUrl: latestUrl,
      };
      return {
        appClientData,
        latestDataChanges,
        instanceDataChanges: [],
        site: {
          contentSrc,
          env,
          meta,
        },
      };
    }
    default: {
      const { site, instance } = await gqlAppLoadClient.GetSiteAndInstanceById({
        siteId,
        instanceId,
      });
      if (!site?.id) {
        throw new SiteLoadError({
          type: 'siteId-not-found',
          siteId,
        });
      }
      if (!instance?.id) {
        // send to "latest"
        // const [{ createToast }, { swapInstanceIdInUrl }] = await Promise.all([
        //   import('@knapsack/toby'),
        //   import('@/domains/branches/utils'),
        // ]);
        // const { newInstanceUrl } = swapInstanceIdInUrl({
        //   newInstanceId: 'latest',
        // });
        // knapsackGlobal.history.push(newInstanceUrl);

        // createToast({
        //   type: 'warning',
        //   title: 'Branch Not Found',
        //   message: `We couldn't find the ${instanceId} branch in your workspace. Switching back to latest.`,
        //   autoClose: 8_000,
        // });
        return loadSiteById({ siteId, instanceId: 'latest' });
      }
      // done with error checking
      const {
        gitProvider,
        isPrivate,
        repoUrl,
        status: siteStatus,
        orgId,
        latestUrl,
        siteInstancesPublishing,
        planId,
      } = site;
      const {
        dataChanges: dataChangesUnTyped,
        gitBranch,
        lastCommittedDataChangeId,
        statusId: instanceStatus,
      } = instance;
      // all the Immer Patches that would be applied to Latest
      const latestDataChanges: KsRemoteChange[] =
        siteInstancesPublishing.flatMap(({ dataChanges }) => {
          return isKsRemoteChanges(dataChanges) ? dataChanges : [];
        });

      // all the Immer Patches that modify AppClientData
      const instanceDataChanges = isKsRemoteChanges(dataChangesUnTyped)
        ? dataChangesUnTyped
        : [];
      const { appClientData, appClientMeta } =
        await getAppClientDataAndCheckMeta({
          siteId,
          appClientUrl: latestUrl,
          siteStatus,
        });
      const prodEnv: EnvProd = {
        type: 'production',
        url: new URL(latestUrl).toString(),
        appClientMeta,
        renderersMeta: appClientData.patternsState.renderers,
        assetSetsState: appClientData.assetSetsState,
      };
      const envDetails = await getEnvDetails({
        envUrl: getActiveEnvUrl({ siteId }),
        prodEnvUrl: latestUrl,
      });
      const env: Env = envDetails instanceof Error ? prodEnv : envDetails;
      const contentSrc: ContentSrc = {
        type: 'cloud-authoring',
        instance: {
          type: 'branch',
          instanceId,
          instanceStatus,
          lastCommittedDataChangeId,
          gitBranch,
        },
      };
      const meta: SiteMeta = {
        siteId,
        isPrivate,
        repoUrl,
        gitProviderType: gitProvider,
        status: siteStatus,
        plan: planId,
        orgId,
        prodEnvUrl: latestUrl,
      };
      return {
        appClientData,
        site: {
          contentSrc,
          meta,
          env,
        },
        instanceDataChanges,
        latestDataChanges,
      };
    }
  }
}

export async function loadSiteFromUrlParams(
  info:
    | {
        type: 'site';
        siteId: string;
        instanceId: string;
      }
    | {
        type: 'custom';
        customUrl: string;
      },
): SiteLoad {
  console.time('loadSite');
  switch (info.type) {
    case 'site': {
      const { siteId, instanceId } = info;
      const results = await loadSiteById({
        siteId,
        instanceId,
      });
      console.timeEnd('loadSite');
      return results;
    }
    case 'custom': {
      const results = await loadSiteFromClientUrl({
        appClientUrl: info.customUrl,
      });
      console.timeEnd('loadSite');
      return results;
    }
    default: {
      const _exhaustiveCheck: never = info;
      throw new Error(`Unhandled info type: ${JSON.stringify(info)}`);
    }
  }
}
