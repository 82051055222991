import {
  getPluginContent as getPluginContentBase,
  renderTemplate as renderTemplateBase,
  checkAppClientUrl,
  getAppClientData,
  prepAppClientData,
  submitDataForFileSave as submitDataForFileSaveBase,
  ENDPOINTS,
  getTemplateSuggestions as getTemplateSuggestionsBase,
} from '@knapsack/app-client-api';
import type { GenericResponse } from '@knapsack/types';
import { FilesApi as Files } from '@knapsack/types';
import { fetcher } from '@knapsack/utils';
import { knapsackGlobal } from '../global';

export { Files, checkAppClientUrl, getAppClientData, prepAppClientData };

const { API_ENDPOINT = 'https://api.knapsack.cloud' } = process.env;

export function getAppClientUrlBase(): string {
  const appClientUrl = knapsackGlobal.appService?.state.context.site?.env.url;

  if (!appClientUrl) {
    console.log(`app state before error:`, knapsackGlobal.getState?.());
    throw new Error(
      `Tried to use AppClient utils before Site Instance was ready and so do not have the appClientUrl`,
    );
  }
  return appClientUrl;
}

export function files(x: Files.Actions): Promise<Files.ActionResponses> {
  if (!x.type) {
    console.error(x);
    throw new Error(`Cannot use data.file() w/no "type"`);
  }

  return fetcher<Files.ActionResponses>({
    url: new URL(ENDPOINTS.files, getAppClientUrlBase()).toString(),
    body: x,
  });
}

/** Verify a file exists */
export async function verifyFile(payload: Files.VerifyData['payload']) {
  return files({
    type: Files.ACTIONS.verify,
    payload,
  }).then((results) => {
    if (results.type !== Files.ACTIONS.verify) {
      throw new Error(`Wrong response`);
    }
    return results.payload;
  });
}

/**
 * Save data up on server to be used in template rendering with `dataId` query param later
 * @returns dataId that is md5 hash
 */
export async function saveData(data: Record<string, unknown>): Promise<string> {
  const {
    ok,
    data: { hash },
  } = await fetcher<GenericResponse<{ hash: string }>>({
    url: new URL('/render-data', API_ENDPOINT).toString(),
    body: data,
  });
  if (!ok || !hash) {
    const msg = `Error in saveData() when trying to store custom form edit data.`;
    console.error(msg, data);
    throw new Error(msg);
  }
  return hash;
}

export async function getPluginContent({ pluginId }: { pluginId: string }) {
  return getPluginContentBase({
    pluginId,
    appClientUrl: getAppClientUrlBase(),
  });
}

export async function renderTemplate(
  options: Parameters<typeof renderTemplateBase>[0]['options'],
) {
  return renderTemplateBase({
    options,
    appClientUrl: getAppClientUrlBase(),
  });
}

export async function submitDataForFileSave(
  state: Parameters<typeof submitDataForFileSaveBase>[0]['state'],
) {
  return submitDataForFileSaveBase({
    urlBase: getAppClientUrlBase(),
    state,
  });
}

export async function getTemplateSuggestions(
  query: getTemplateSuggestionsBase.ReqParams,
) {
  return getTemplateSuggestionsBase.getTemplateSuggestions({
    appClientUrl: getAppClientUrlBase(),
    query,
  });
}
